import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));

export const auth = {
    namespaced: true,
    state: {
        loggedIn: user !== null ? true:false,
        loginConfirmed: false,
        mfaConfirmed: false,
        user: user ? user.user:null,
        userId: null,
    },
    actions:{
        /**
         * Login
         * @param commit
         * @param user
         * @returns {Promise<unknown>}
         */
        login({ commit }, user) {
            return AuthService.login(user).then(
                data => {
                    commit('loginSuccess', data);
                    return Promise.resolve(data);
                },

                error => {
                    commit ('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        /**
         * Confirm MFA
         * @param commit
         * @param mfaCode
         * @returns {Promise<unknown>}
         */
        confirmMFA( { commit }, data) {
            return AuthService.verifyMFA(data).then(
                response => {
                    commit('mfaSuccess', response);
                    return Promise.resolve(response);
                },
                error => {
                    commit('mfaFailure');
                    return Promise.reject(error);
                }
            );
        },
        /**
         * Logout
         * @param commit
         */
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
    },
    mutations: {
        loginSuccess(state, data) {
            state.loginConfirmed = true;
            state.userId = data.userId;
        },
        mfaSuccess(state, data) {
            state.loggedIn = true;
            state.mfaConfirmed = true;
            state.user = Object.assign({}, data.user);
        },
        mfaFailure(state) {
            state.loggedIn = false;
            state.user = Object.assign({}, {});
            state.mfaConfirmed = false;
        },
        loginFailure(state) {
            state.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.loggedIn = false;
            state.user = null;
            state.userId = null;
            state.mfaConfirmed = false;
        },
    }
}