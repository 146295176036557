<template>
    <div>
        <v-card tile outlined>
            <v-card-title>
                <v-icon left>mdi-note</v-icon>Notes <v-btn icon color="primary" @click="openNewNoteDialog"><v-icon>mdi-plus</v-icon></v-btn>
                <v-spacer></v-spacer>
                <v-text-field
                        label="Search notes..."
                        append-icon="mdi-magnify"
                        single-line
                        hide-details
                        rounded
                        filled
                        dense
                        v-model="search"
                ></v-text-field>
            </v-card-title>
            <v-card-text>
                <v-data-table
                        :headers="headers"
                        :items="getNotes"
                        fixed-header
                        :search="search"
                >
                    <template v-slot:no-data>
                        <v-card flat color="transparent">
                            <v-card-text>
                                <v-avatar size="128" tile class="mb-3">
                                    <v-icon size="128">mdi-folder-alert-outline</v-icon>
                                </v-avatar>
                                <h3>There is nothing here....</h3>
                                <h4>Create something awesome!</h4>
                            </v-card-text>
                        </v-card>
                    </template>
                    <template v-slot:item.note="{item}">
                        <v-btn text tile color="primary" @click="openViewNoteDialog(item)"><span class="text-truncate text-left" style="width: 150px">{{item.note}}</span></v-btn>
                    </template>
                    <template v-slot:item.createdAt="{item}">
                        <span>{{ item.createdAt | moment("MMM Do YYYY, h:mm a") }}</span>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <!--Dialogs-->
        <newNote ref="newNote" :domain="domain"></newNote>
        <viewNote ref="viewNote"></viewNote>

    </div>
</template>

<script>
    import newNote from "./dialogs/newNote";
    import viewNote from "./dialogs/viewNote";

    export default {
        name: "notes",
        components: {newNote, viewNote},
        props:['domain'],
        computed: {
            getNotes() {
                return this.$store.getters["domains/notes/getDomainNotes"]
            }
        },
        data() {
            return{
                headers:[
                    {text: 'Note', value: 'note'},
                    {text: 'Created At', value: 'createdAt'}
                ],
                search: ''
            }
        },
        methods:{
            openViewNoteDialog(note) {
                this.$refs.viewNote.open(note).then(dialog=>{
                    if (dialog.success) {
                        //todo
                    }
                })
            },
            openNewNoteDialog() {
                this.$refs.newNote.open().then(dialog=>{
                    if (dialog.success) {
                        //todo
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>