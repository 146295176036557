<template>
    <v-dialog v-model="dialog" width="650">
        <v-card>
            <v-card-title>
                <h3 class="grey--text"><v-icon>mdi-karate</v-icon>New Rate</h3>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <v-row>
                        <v-col>
                            <v-text-field
                                    label="Customer Type"
                                    rounded
                                    filled
                                    v-model="newRate.userType"
                                    :rules="[v=>!!v||'A customer type is required']"
                            ></v-text-field>
                            <v-text-field
                                    label="Cost"
                                    rounded
                                    filled
                                    v-model="newRate.cost"
                                    :rules="[v=>!!v||'A cost is required']"
                                    class="mt-3"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn color="success" icon @click="createRate" :loading="loading">
                    <v-icon>mdi-check</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import authHeader from "../../../../../services/auth-header";

    export default {
        name: "newRate",
        props: ['domain'],
        data() {
            return {
                dialog: false,
                resolve: null,
                reject: null,
                newRate: {
                    userType: '',
                    cost: '',
                },
                loading: false,
            }
        },
        watch: {
            dialog(value) {
                if (!value) {
                    this.$refs.form.reset()
                    this.newRate = Object.assign(this.newRate, {
                        userType: '',
                        cost: '',
                    })
                }
            }
        },
        methods: {
            createRate() {
                if (!this.$refs.form.validate()) {
                    return
                }
                this.newRate.domain = this.domain
                this.newRate.UserId = this.$store.state.auth.user.id
                this.loading = true
                this.$axios.post('domains/rates',this.newRate,{
                    headers: authHeader()
                }).then(res=>{
                    this.resolve({success: true, data: res.data.data})
                    setTimeout(()=>{
                        this.dialog = false
                        this.loading = false
                        this.$store.dispatch('domains/rates/pushRates', res.data.data)
                    }, 200)
                }).catch(error=>{
                    this.loading = false
                    this.$root.tmSnackbar.open('error', 'mdi-alert', error.response.message)
                })
            },
            open() {
                this.dialog = true
                return new Promise((resolve, reject) => {
                    this.resolve = resolve
                    this.reject = reject
                })
            },
            close() {
                this.resolve(false)
                this.dialog = false
            }
        }
    }
</script>

<style scoped>

</style>