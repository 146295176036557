import { render, staticRenderFns } from "./domains.vue?vue&type=template&id=d38aa89c&scoped=true&"
import script from "./domains.vue?vue&type=script&lang=js&"
export * from "./domains.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d38aa89c",
  null
  
)

export default component.exports