<template>
    <div>
        <v-row>
            <v-col>
                <v-toolbar flat color="transparent" class="mb-6">
                    <h2>Domain Users</h2>
                    <v-divider vertical inset class="mx-3"></v-divider>
                    <v-btn fab small color="primary"><v-icon>mdi-plus</v-icon></v-btn>
                    <v-spacer></v-spacer>
                    <v-text-field
                            label="Search users...."
                            append-icon="mdi-magnify"
                            filled
                            rounded
                            single-line
                            hide-details
                            v-model="search"
                    ></v-text-field>
                </v-toolbar>
                <v-data-table
                        :headers="headers"
                        :items="items"
                        :search="search"
                        @click:row="viewUser"
                >
                </v-data-table>
            </v-col>
        </v-row>
        <v-bottom-sheet
                v-model="nav"
                inset
        >
            <v-sheet class="px-6 py-3">
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>{{user.first_name + ' ' + user.last_name}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon color="error" @click="nav = !nav"><v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar>
                <v-row>
                    <v-col>
                        <v-tabs>
                            <v-tab>Contacts</v-tab>
                            <v-tab-item class="pa-6">
                                <v-data-table
                                        :headers="contactHeaders"
                                        :items="contactItems"
                                        :search="contactSearch"
                                ></v-data-table>
                            </v-tab-item>
                        </v-tabs>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>

<script>
    import authHeader from "../../../../services/auth-header";

    export default {
        name: "domainUsers",
        data() {
            return{
                nav: false,
                contactHeaders:[
                    {text: 'Synced', value: 'synced', align: 'center'},
                    {text: 'First Name', value: 'first_name'},
                    {text: 'Last Name', value: 'last_name'},
                    {text: 'Work Phone', value: 'work_phone'},
                    {text: 'Home Phone', value: 'home_phone'},
                    {text: 'Cell Phone', value: 'cell_phone'},
                    {text: 'Actions', value: 'actions', align: 'center'}
                ],
                contactItems: [],
                contactSearch: '',
                headers:[
                    {text: 'Dir', value: 'dir'},
                    {text: 'Extension', value: 'user'},
                    {text: 'Fist Name', value: 'first_name'},
                    {text: 'Last Name', value: 'last_name'},
                    {text: 'Group', value: 'group'},
                    {text: 'Site', value: 'site'},
                    {text: 'Domain', value: 'domain'}
                ],
                items: [],
                search: '',
                user: {}
            }
        },
        watch: {
            nav(value) {
                if (!value) {
                    this.user = Object.assign({}, {})
                }
            }
        },
        created() {
            this.getUsers()
        },
        methods:{
            viewUser(value) {
                this.$axios.get('/netsapiens/domain/user', {
                    params: {
                        user: value.user,
                        domain: value.domain
                    },
                    headers: authHeader()
                }).then(res => {
                    this.user = Object.assign(this.user, res.data.data[0])
                    this.$axios.get('/netsapiens/domain/user/contacts', {
                        params:{
                            user: value.user,
                            domain: value.domain
                        },
                        headers: authHeader()
                    }).then(success=>{
                        this.contactItems = success.data.data
                        this.nav = true
                    }).catch(err=>{
                        this.nav = true
                        console.log(err)
                    })
                }).catch(err => {
                    console.log(err)
                });
            },
            getUsers() {
                this.$axios.get('/netsapiens/domain/users', {
                    params:{
                        domain: this.$store.getters["domains/getDomain"].domain
                    },
                    headers: authHeader()
                }).then(res=>{
                    this.items = res.data.data
                }).catch(err=>{
                    console.log(err)
                })
            }
        }
    }
</script>

<style scoped>

</style>