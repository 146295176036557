<template>
    <div class="fill-height">
        <v-toolbar flat>
            <v-btn fab small color="purple" @click="goBack"><v-icon>mdi-keyboard-backspace</v-icon></v-btn>
            <v-divider vertical inset class="mx-3"></v-divider>
            <v-toolbar-title class="mr-l">{{$store.getters["domains/getDomain"].description}}</v-toolbar-title>
        </v-toolbar>
        <v-row class="fill-height">
            <v-col>
                <iframe src="https://login.integritalk.net" frameborder="0" width="100%" height="800">

                </iframe>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: "integriTalk",
        data() {
            return{

            }
        },
        methods:{
            goBack() {
                this.$router.push({name: 'domain.view', params:{domain: this.$store.getters["domains/getDomain"].domain}});
            }
        }
    }
</script>

<style scoped>

</style>