<template>
    <div>
        <v-row>
            <v-col>
                <h1>Administration Home</h1>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: "administrationHome"
    }
</script>

<style scoped>

</style>