<template>
  <div>
    <v-skeleton-loader
        v-if="items.length === 0"
        type="table"
        class="mx-auto"
        loading
    ></v-skeleton-loader>
    <v-row v-else>
      <v-col>
        <v-toolbar height="80" style="position: sticky; top: 65px; z-index: 100" dense flat class="mb-3">
          <h1 class="headline">Domains</h1>
          <v-divider vertical class="mx-3" inset/>
          <v-btn icon color="primary"><v-icon>mdi-plus</v-icon></v-btn>
          <v-spacer></v-spacer>
          <v-text-field
              clearable
              label="Search..."
              v-model="search"
              append-icon="mdi-account-search"
              single-line
              hide-details
              rounded
              filled
              dense
              color="secondary"
          ></v-text-field>
        </v-toolbar>
        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            disable-pagination
            dense
        >
          <template v-slot:item.domain="{item}">
            <v-btn text tile color="primary" @click="viewDomain(item)">{{item.domain}}</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import NetSapiensService from "../../../../services/netsapiens.service";

export default {
  name: "domainsHome",
  data() {
    return{
      headers: [
        {text: 'Domain', value: 'domain'},
        {text: 'Description', value: 'description'},
        {text: 'Reseller', value: 'territory'},
        {text: 'Subscribers', value: 'sub_count', align: 'center'},
        {text: 'Actions', value: 'actions', align: 'center'}
      ],
      items: [],
      search: '',
      viewingDomain: false,
      setInterval: null,
    }
  },
  beforeMount() {
    NetSapiensService.getDomains().then(res => {
      this.items = res.data.data;
    });
    this.setInterval = setInterval(() => {
      this.refreshDomains();
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.setInterval);
    this.setInterval = null;
  },
  methods:{
    viewDomain(domain) {
      this.$router.push({name: 'domain.view', params:{domain: domain.domain}});
    },
    refreshDomains() {
      NetSapiensService.getDomains().then(res => {
        this.items = [];
        this.items = res.data.data;
      });
    }
  }
}
</script>

<style scoped>

</style>