import axios from 'axios'
axios.defaults.headers = localStorage.getItem('jwt') !== '' ? {'authorization':localStorage.getItem('jwt')}:{}
const API_URL = `${process.env.VUE_APP_API_URL}/netsapiens/`;

class NetsapiensService{

    /**
     * Get Domains
     * @returns {AxiosPromise<any>}
     */
    getDomains() {
        return axios
            .get(API_URL + 'domains');
    }

    /**
     * Get Total Active Calls across all domains
     * @returns {AxiosPromise<any>}
     */
    refreshTotalActiveCalls() {
        return axios
            .get(API_URL + 'total_active_calls');
    }
}
export default new  NetsapiensService();