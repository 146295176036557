<template>
    <v-dialog v-model="dialog" width="650">
        <v-card>
            <v-card-title>
                <h3><v-icon left>mdi-key</v-icon>New Password</h3>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <v-row>
                        <v-col>
                            <v-text-field
                                    label="Description"
                                    filled
                                    rounded
                                    :rules="[v=>!!v||'Description is required']"
                                    v-model="newPassword.description"
                            ></v-text-field>
                            <v-text-field
                                    label="Username"
                                    filled
                                    rounded
                                    :rules="[v=>!!v||'Username is required']"
                                    v-model="newPassword.username"
                            ></v-text-field>
                            <v-text-field
                                    label="Password"
                                    filled
                                    rounded
                                    :rules="[v=>!!v||'Password is required']"
                                    v-model="newPassword.password"
                            ></v-text-field>
                            <v-btn outlined color="primary" tile @click="generatePassword">Generate</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn color="success" icon @click="save">
                    <v-icon>mdi-check</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import authHeader from '../../../../../services/auth-header';
    export default {
        name: "newPassword",
        props: ['domain'],
        data() {
            return {
                dialog: false,
                resolve: null,
                reject: null,
                newPassword:{
                    description: '',
                    username: '',
                    password: ''
                }
            }
        },
        watch: {
            dialog(value){
                if (!value) {
                    this.$refs.form.reset()
                    this.newPassword = Object.assign(this.newPassword, {
                        description: '',
                        username: '',
                        password: ''
                    })
                }
            }
        },
        methods: {
            generatePassword() {
                this.newPassword.password = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            },
            save() {
                if (!this.$refs.form.validate()) {
                    return;
                }
                this.newPassword.domain = this.domain
                this.$axios.post('/domains/passwords', this.newPassword , {headers: authHeader()}).then(res=>{
                    this.resolve({success: true, data: res.data.data})
                    setTimeout(() =>{
                        this.dialog = false
                    }, 100)
                })
            },
            open() {
                this.dialog = true
                return new Promise((resolve, reject) => {
                    this.resolve = resolve
                    this.reject = reject
                })
            },
            close() {
                this.resolve(false)
                this.dialog = false
            }
        }
    }
</script>

<style scoped>

</style>