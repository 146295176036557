export const favorites = {
    namespaced: true,
    state: {
        favorites: []
    },
    getters: {
        getFavorites: (state) =>{
            return state.favorites
        },
        getFavorite: (state) => (domain) => {
            return state.favorites.find(fav => fav.domain === domain)
        },
        isFavorite: (state) => (domain) =>{
            return !!state.favorites.find(fav => fav.domain === domain)
        }
    },
    actions: {
        refreshFavorite({commit}, data) {
            commit('refreshFavorites', data)
        },
        pushFavorite({commit}, data) {
            commit('pushFavorite', data)
        },
        initFavorites({commit}, data) {
            commit('initFavorites', data)
        }
    },
    mutations: {
        initFavorites(state, data) {
            state.favorites = data
        },
        pushFavorite(state, data) {
            state.favorites.push(data)
        },
        refreshFavorites(state, data) {
            state.favorites = []
            state.favorites = data
        }
    },
}