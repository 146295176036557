<template>
    <div>
        <v-row align="center" justify="start">
            <v-col lg="2">
                <v-card outlined color="primary" dark elevation="6">
                    <v-row align="center" justify="space-around">
                        <v-col lg="3">
                            <v-icon x-large>mdi-account</v-icon>
                        </v-col>
                        <v-col lg="3">
                            <div class="display-2">{{users.length}}</div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <div class="mt-6"></div>
        <v-row align="center" justify="center">
            <v-col>
                <v-toolbar flat>
                    <v-tooltip fixed top>
                        <template v-slot:activator="{on}">
                            <v-btn icon color="primary" v-on="on" @click="openAddUserDialog"><v-icon>mdi-plus</v-icon></v-btn>
                        </template>
                        <v-icon color="secondary">mdi-plus</v-icon> Add new user
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-text-field
                            label="Search users..."
                            append-icon="mdi-magnify"
                            rounded
                            outlined
                            color="secondary"
                            hide-details
                            single-line
                    ></v-text-field>
                </v-toolbar>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                        :headers="headers"
                        :items="users"
                        :search="search"
                >
                    <!--Roles-->
                    <template v-slot:item.roles="{item}">
                        {{getRoles(item)}}
                    </template>
                    <!--Actions-->
                    <template v-slot:item.actions="{item}">
                        <v-icon @click="viewUser(item)" class="mr-3">mdi-eye</v-icon>
                        <v-icon @click="deleteUser(item)" color="error">mdi-delete</v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <!--View User Dialog-->
        <view-user-dialog ref="viewUserDialog"></view-user-dialog>
        <!--New User Dialog-->
        <new-user-dialog ref="newUserDialog"></new-user-dialog>
    </div>
</template>

<script>
    import UsersRepository from "../../../../Repositories/UsersRepository";
    import viewUserDialog from "./viewUserDialog";
    import newUserDialog from "./newUserDialog";

    export default {
        name: "users",
        components: {viewUserDialog, newUserDialog},
        data() {
            return{
                headers:[
                    {text: 'Name', value: 'first_name'},
                    {text: 'Email', value: 'email'},
                    {text: 'Roles', value: 'roles'},
                    {text: 'Actions', value: 'actions', align: 'center'}
                ],
                users: [],
                search: ''
            }
        },
        beforeMount() {
            UsersRepository.getUsers().then(res => {
                this.users = res.data;
            });
        },
        methods:{
            openAddUserDialog() {
                this.$refs.newUserDialog.open().then(res => {
                    console.log(res);
                });
            },
            getRoles(item) {
                let roles = item.roles.map(role => {
                    return role.name;
                });
                return roles.join(', ');
            },
            viewUser(user) {
                this.$refs.viewUserDialog.open(user).then(res => {
                    console.log(res);
                });
            },
            // eslint-disable-next-line no-unused-vars
            deleteUser(user) {
                UsersRepository.deleteUser(user).then(
                    () => {
                        // eslint-disable-next-line no-undef
                        Vue.delete(this.users, this.users.findIndex((t) => t.id === user.id));
                    },
                    error => {
                        console.log(error);
                    }
                );
            }
        }
    }
</script>

<style scoped>

</style>