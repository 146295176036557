import axios from 'axios';
const API_URL = process.env.VUE_APP_API_URL + '/auth/';

class AuthService{
    login(user) {
        return axios
            .post(API_URL + 'login', {
                email: user.email,
                password: user.password
            })
            .then(res => {
                return res.data;
            });
    }

    verifyMFA(data) {
        return axios
            .post(API_URL + 'mfa', {
                mfaCode: data.mfaCode,
                userId: data.userId,
            })
            .then(res => {
                localStorage.setItem('jwt', res.data.accessToken)
                localStorage.setItem('user', JSON.stringify(res.data));
                return res.data;
            });
    }

    logout() {
        localStorage.removeItem('user');
    }
}

export default new AuthService();