import Vue from 'vue'
import Vuex from 'vuex'

import {auth} from './auth.module';
import {domains} from './domains/domains.module'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  modules: {
    auth,
    domains,
  }
})
