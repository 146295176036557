<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  data() {
    return{

    }
  },
  created() {
    //this.$vuetify.theme.dark = true
  },
  beforeMount() {
    if (this.$store.state.auth.loggedIn) {
      this.$router.push({name: 'dashboard'});
    } else {
      this.$router.push({name: 'auth.login'});
    }
  },
};
</script>
