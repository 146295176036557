<template>
    <v-tabs icons-and-text>
        <v-tab>
            Global Contacts
            <v-icon>mdi-contacts</v-icon>
        </v-tab>
        <v-tab-item class="px-3 py-6">
            <v-row>
                <v-col>
                    <!--No Global Contacts Alert-->
                    <v-alert
                            prominent
                            type="error"
                    >
                        <v-row align="center">
                            <v-col class="grow">
                                There is no global contact list for this domain!
                            </v-col>
                            <v-col class="shrink">
                                <v-btn color="success" @click="openglobalContactCSVDialog">Fix Now</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                    <!--Gobal Contacts Toolbar-->
                    <v-toolbar v-if="!loadingUsers">
                        <h3 class="mr-3">Global contacts</h3>
                        <v-divider vertical inset class="mx-3"></v-divider>
                        <v-tooltip fixed top>
                            <template v-slot:activator="{on}">
                                <v-btn icon color="success" v-on="on"><v-icon>mdi-download</v-icon></v-btn>
                            </template>
                            Download current global contact list
                        </v-tooltip>
                        <v-tooltip fixed top>
                            <template v-slot:activator="{on}">
                                <v-btn v-on="on" icon color="pink"><v-icon>mdi-refresh</v-icon></v-btn>
                            </template>
                            Resynchronize global contacts
                        </v-tooltip>
                        <v-tooltip fixed top>
                            <template v-slot:activator="{on}">
                                <v-btn v-on="on" icon color="yellow"><v-icon>mdi-pencil</v-icon></v-btn>
                            </template>
                            Edit global contacts
                        </v-tooltip>
                        <v-spacer></v-spacer>
                        <v-tooltip fixed top>
                            <template v-slot:activator="{on}">
                                <v-btn icon v-on="on" color="error"><v-icon>mdi-delete</v-icon></v-btn>
                            </template>
                            Delete global contact list
                        </v-tooltip>
                    </v-toolbar>
                </v-col>
            </v-row>
            <!--Loading Users Skeleton Loader-->
            <v-row v-if="loadingUsers">
                <v-col>
                    <h3 class="mb-3 font-weight-light">Loading {{users.length}} users. Please wait...</h3>
                    <v-skeleton-loader
                            class="mx-auto"
                            type="table"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
            <!--Loaded Users & Show Data table-->
            <v-row v-else align="start" justify="start">
                <v-col cols="5">
                    <v-text-field
                            rounded
                            filled
                            label="Search"
                            append-icon="mdi-magnify"
                            v-model="searchUsers"
                            dense
                            clearable
                    ></v-text-field>
                    <v-data-table
                            :headers="[{text: 'Extension', value: 'user', align: 'left', width: '10em'},{text: 'Name', value: 'name', align: 'left'}]"
                            :items="users"
                            :search="searchUsers"
                            @click:row="SelectUser"
                            dense
                            :items-per-page="25"
                            fixed-header
                    ></v-data-table>

                </v-col>
                <v-divider vertical></v-divider>
                <v-col class="d-flex text-center">
                    <div
                            v-if="!selected"
                            class="title grey--text text--lighten-1 font-weight-light"
                    >
                        Select a User
                    </div>
                    <v-card
                            v-else
                            :key="selected.id"
                            class="pt-6 mx-auto"
                            flat
                    >
                        <v-card-text>
                            <v-avatar
                                    v-if="avatar"
                                    size="164"
                            >
                                <v-img
                                        :src="avatar"
                                        class="mb-6"
                                ></v-img>
                            </v-avatar>
                            <h3 class="headline mb-2">
                                {{ selected.name }}
                            </h3>
                            <div class="blue--text mb-2">
                                {{ selected.email }}
                            </div>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-row
                                class="text-left"
                                tag="v-card-text"
                        >
                            <v-col>
                                <v-text-field
                                        filled
                                        rounded
                                        label="Search"
                                        append-icon="mdi-magnify"
                                        v-model="searchContacts"
                                        clearable
                                        dense
                                ></v-text-field>
                                <v-data-table
                                        :headers="headers"
                                        :items="selected.contacts"
                                        :search="searchContacts"
                                        dense
                                >
                                    <template v-slot:item.email="{item}">
                                        <v-chip v-for="email in item.email.split(';')" :key="email" class="my-1" small color="primary">
                                            {{email}}
                                        </v-chip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-tab-item>

        <!--Dialogs-->
        <dialogAddSingleContact ref="dialogAddSingleContact"></dialogAddSingleContact>
        <globalContactCSVDialog ref="globalContactCSVDialog" :users="users"></globalContactCSVDialog>

    </v-tabs>
</template>

<script>
    import dialogAddSingleContact from "./dialogs/dialogAddSingleContact";
    import globalContactCSVDialog from "./dialogs/globalContactCSVDialog";
    import { AvatarGenerator } from 'random-avatar-generator';

    export default {
        name: "bulkEditor",
        props: ['domain'],
        components: {dialogAddSingleContact, globalContactCSVDialog},
        data() {
            return{
                headers:[
                    {text: 'Extension', value: 'user'},
                    {text: 'Name', value: 'first_name'},
                    {text: 'Cell Phone', value: 'cell_phone'},
                    {text: 'Work Phone', value: 'work_phone'},
                    {text: 'Home Phone', value: 'home_phone'},
                    {text: 'Email', value: 'email'}
                ],
                active: {},
                searchUsers: '',
                searchContacts: '',
                avatar: null,
                users: [],
                csv: null,
                loadingUsers: true,
            }
        },
        watch: {
            selected: 'randomAvatar',
        },
        computed: {
            items () {
                return [
                    {
                        name: 'Users',
                        children: this.users,
                    },
                ]
            },
            selected () {
                if(this.active === undefined) return;
                const id = this.active.id
                return this.users.find(user => user.id === id)
            },
        },
        beforeMount() {
            this.GetUsers();
        },
        methods: {
            openglobalContactCSVDialog() {
                this.$refs.globalContactCSVDialog.open().then(res=>{
                    if (res.success) {
                        res.contacts.data.forEach(contact=>{
                            this.items.push({
                                first_name: contact.first_name,
                                last_name: contact.last_name,
                                email: contact.email,
                                cell_phone: contact.cell_phone,
                                work_phone: contact.work_phone,
                                home_phone: contact.home_phone,
                            })
                        })
                        this.resync = true
                    }
                })
            },
            async GetUsers() {
                if (this.users.length > 0) {
                    this.users = []
                }
                this.$axios.get('/netsapiens/domain/users', {
                    params: {
                        domain: this.$store.getters["domains/getDomain"].domain
                    },
                }).then(async res => {
                    for (const user of res.data.data) {
                        await this.AddContact(user);
                    }
                    this.loadingUsers = false;
                }).catch(()=>{
                    this.loadingUsers = false;
                });
            },
            SelectUser(user) {
                this.active = user;
            },
            randomAvatar() {
                const genny = new AvatarGenerator();
                this.avatar = genny.generateRandomAvatar();
            },

            async AddContact(user) {
                return new Promise((resolve, reject) =>{
                    this.$axios.get('netsapiens/domain/user/contacts', {
                        params: {
                            domain: this.$store.getters['domains/getDomain'].domain,
                            user: user.user
                        }
                    }).then(ress =>{
                        this.users.push({
                            id: user.user,
                            user: user.user,
                            name: `${user.first_name} ${user.last_name}`,
                            email: 'asldkjf@asldkfj.com',
                            first_name: user.first_name,
                            last_name: user.last_name,
                            contacts: ress.data.data
                        })
                        return resolve(true)
                    }).catch( () =>{
                        return reject(false);
                    })
                })
            }
        }
    }
</script>

<style scoped>

</style>