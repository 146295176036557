<template>
    <v-dialog v-model="dialog" width="650">
        <v-card>
            <v-card-title>
                <h3 class="grey--text"><v-icon left>mdi-note</v-icon>New Note</h3>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <v-row>
                        <v-col>
                            <v-textarea
                                    label="Note"
                                    filled
                                    rounded
                                    :rule="[v=>!!v||'A note is required!']"
                                    v-model="newNote.note"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn color="success" icon @click="createNote">
                    <v-icon>mdi-check</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import authHeader from "../../../../../services/auth-header";

    export default {
        name: "newNote",
        props: ['domain'],
        data() {
            return {
                dialog: false,
                resolve: null,
                reject: null,
                newNote:{
                    note: '',
                }
            }
        },
        watch: {
            dialog(value) {
                if (!value) {
                    this.$refs.form.reset()
                    this.newNote = Object.assign(this.newNote, {
                        note: '',
                    })
                }
            }
        },
        methods: {
            createNote() {
                if (!this.$refs.form.validate()) {
                    return
                }
                this.newNote.UserId = this.$store.state.auth.user.id
                this.newNote.domain = this.domain
                this.$axios.post('domains/notes', this.newNote, {
                    headers: authHeader()
                }).then(res => {
                    this.resolve({success: true, data: res.data.data})
                    this.$store.dispatch('domains/notes/pushNotes', res.data.data)
                    setTimeout(()=>{
                        this.dialog = false
                    }, 200)
                }).catch(error=>{
                    this.$root.tmSnackbar.open('error', 'mdi-alert', error.response.message)
                })
            },
            open() {
                this.dialog = true
                return new Promise((resolve, reject) => {
                    this.resolve = resolve
                    this.reject = reject
                })
            },
            close() {
                this.resolve(false)
                this.dialog = false
            }
        }
    }
</script>

<style scoped>

</style>