export const rates = {
    namespaced: true,
    state: {
        rates: []
    },
    getters: {
        getDomainRates: (state) => {
            return state.rates
        }
    },
    actions: {
        initDomainRates({commit}, data) {
            commit('initDomainRates', data)
        },
        pushRates({commit}, data) {
            commit('pushRates', data)
        },
        refreshRates({commit}, data) {
            commit('refreshRates', data)
        }
    },
    mutations: {
        initDomainRates(state, data) {
            state.rates = data
        },
        pushRates(state, data) {
            state.rates.push(data)
        },
        refreshRates(state, data) {
            state.rates = []
            state.rates = data
        }
    },
}