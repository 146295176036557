<template>
    <v-card tile outlined>
        <v-card-title>
            <v-icon left>mdi-key</v-icon>Passwords <v-btn icon color="primary" @click="openNewPasswordDialog"><v-icon>mdi-plus</v-icon></v-btn>
            <v-spacer></v-spacer>
            <v-text-field
                    label="Search passwords..."
                    append-icon="mdi-magnify"
                    filled
                    rounded
                    dense
                    hide-details
                    single-line
                    v-model="search"
            ></v-text-field>
        </v-card-title>
        <v-card-text>
            <v-data-table
                    :headers="headers"
                    :items="getPasswords"
                    fixed-header
                    :search="search"
            >
                <template v-slot:no-data>
                    <v-card flat color="transparent">
                        <v-card-text>
                            <v-avatar size="128" tile class="mb-3">
                                <v-icon size="128">mdi-folder-alert-outline</v-icon>
                            </v-avatar>
                            <h3>There is nothing here....</h3>
                            <h4>Create something awesome!</h4>
                        </v-card-text>
                    </v-card>
                </template>
                <template v-slot:item.actions="{item}">
                    <v-btn class="mr-3" icon color="error" @click="deletePassword(item)"><v-icon>mdi-delete</v-icon></v-btn>
                </template>
                <template v-slot:item.password="{item}">
                    {{item.password}} <v-icon color="primary" right small v-clipboard:copy="item.password" v-clipboard:success="onCopy" style="cursor: copy">mdi-content-paste</v-icon>
                </template>
            </v-data-table>
        </v-card-text>

        <!--Dialgos-->
        <newPassword ref="newPassword" :domain="domain"></newPassword>

    </v-card>
</template>

<script>
    import newPassword from "./dialogs/newPassword";
    import authHeader from "../../../../services/auth-header";

    export default {
        name: "passwords",
        props: ['domain'],
        components: {newPassword},
        data() {
            return{
                headers: [
                    {text: 'Description', value: 'description'},
                    {text: 'User', value: 'username'},
                    {text: 'Password', value: 'password', align: 'center'},
                    {text: 'Actions', value: 'actions', align: 'right'}
                ],
                search: ''
            }
        },
        computed: {
            getPasswords() {
                return this.$store.getters["domains/passwords/getDomainPasswords"]
            }
        },
        methods:{
            onCopy() {
                this.$root.tmSnackbar.open('success', 'mdi-check', 'Password copied!')
            },
            deletePassword(password) {
                this.$root.confirm.open({title: 'Are you sure', message: 'Are you sure you want to delete this password?'}).then(dialog => {
                    if (dialog) {
                        this.$axios.delete('domains/passwords', {
                            params: {
                                id: password.id
                            },
                            headers: authHeader()
                        }).then(() => {
                            this.$axios.get('domains/passwords', {
                                params: {
                                    domain: this.domain
                                },
                                headers: authHeader()
                            }).then(res => {
                                this.$store.dispatch('domains/passwords/refreshDomainPasswords', res.data.data)
                            });
                        });
                    }
                });
            },
            openNewPasswordDialog() {
                this.$refs.newPassword.open().then(dialog => {
                    if (dialog.success) {
                        this.$store.dispatch('domains/passwords/pushDomainPasswords', dialog.data)
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>