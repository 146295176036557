"use strict";

import Vue from 'vue';
import axios from "axios";
import router from "../router";
import store from '../store'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: process.env.VUE_APP_API_URL
  // timeout: 60 * 1000, // Timeout
  //withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function(config) {
      // Do something before request is sent
        let url = `${config.baseURL}/${config.url}`
        if(url.includes('/api/')){
            config.headers = localStorage.getItem('jwt') !== '' ? {'authorization':localStorage.getItem('jwt')}:{}
        }
      return config;
    },
    function(error) {
      // Do something with request error
      return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function(response) {
      // Do something with response data
      return response;
    },
    function(error) {
        if (!error.response.status) {
            console.error('axios.js no response.status error report')
            console.error(error)
        }
      // Do something with response error
        if (error.response.status === 403) {
            store.dispatch('auth/logout');
            router.push('/auth/login');
        }
        if (error.response.status === 401) {
            store.dispatch('auth/logout');
            router.push('/auth/login');
        }
      return Promise.reject(error);
    }
);

// eslint-disable-next-line no-unused-vars
Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
