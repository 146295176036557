<template>
    <v-app id="app">

        <v-app-bar app flat color="primary" clipped-left dense dark>
            <v-toolbar-title class="white--text">
                <v-img src="/images/logo_grey_white.png" contain width="150" @click="$router.push({name: 'dashboard'})" style="cursor: pointer"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu offset-y>
                <template v-slot:activator="{on}">
                    <v-btn icon v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                </template>
                <v-list>
                    <v-list-item link :to="{name: 'administration'}">
                        <v-list-item-avatar><v-icon>mdi-cogs</v-icon></v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>Administration</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item link @click="logout">
                        <v-list-item-avatar><v-icon>mdi-logout</v-icon></v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

        <v-navigation-drawer
                app
                :mini-variant.sync="mini"
                expand-on-hover
                clipped
        >
            <v-list-item class="px-2">
                <v-list-item-avatar color="purple" class="elevation-6">
                    <v-img src="https://www.gravatar.com/avatar/00000000000000000000000000000000?d=retro&f=y"></v-img>
                </v-list-item-avatar>

                <v-list-item-title>{{getLoggedInUser.email}}</v-list-item-title>

                <v-btn
                        icon
                        @click.stop="mini = !mini"
                >
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            </v-list-item>
            <v-list flat>
                <v-list-item
                        v-for="page in pages"
                        :key="page.router"
                        :to="{name: page.routerTo}"
                        link
                        color="info"
                >
                    <v-list-item-icon>
                        <v-icon>{{ page.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{page.title}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <template v-slot:append>
                <v-list-item
                        link
                >
                    <v-list-item-icon>
                        <v-icon>mdi-cogs</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Settings</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>

        </v-navigation-drawer>

        <v-content>
            <v-container>
                <router-view></router-view>
            </v-container>
        </v-content>

        <!--Confirm Dialog-->
        <confirm-dialog ref="confirmDialog"></confirm-dialog>
        <tmSnackbar ref="tmSnackbar"></tmSnackbar>

    </v-app>
</template>

<script>
    import confirmDialog from "../../../components/confirmDialog";
    import tmSnackbar from "../../../components/tmSnackbar";

    export default {
        name: "AppHome",
        components: {confirmDialog, tmSnackbar},
        data() {
            return{
                mini: true,
                pages: [
                    {title: 'Dashboard', routerTo:'dashboard', icon: 'mdi-view-dashboard'},
                    {title: 'Domains', routerTo:'domains.home', icon: 'mdi-castle'},
                    {title: 'Acrobits', routerTo: 'acrobits', icon: 'mdi-fountain'}
                ],
            }
        },
        computed: {
            getLoggedInUser() {
                return this.$store.state.auth.user;
            }
        },
        mounted() {
            this.$root.confirm = this.$refs.confirmDialog
            this.$root.tmSnackbar = this.$refs.tmSnackbar
        },
        methods: {
            logout() {
                this.$refs.confirmDialog.open({
                    title: 'Logout?',
                    message: 'Are you sure you want to logout?'
                }).then(res => {
                    if (res) {
                        this.$store.dispatch('auth/logout');
                        this.$router.push({name: 'auth.login'})
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>