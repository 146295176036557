<template>
    <v-dialog v-model="dialog" width="450">
        <v-card>
            <v-toolbar flat>
                <v-icon left color="secondary">mdi-account</v-icon>{{user.first_name}} {{user.last_name}}
                <v-spacer></v-spacer>
                <v-btn v-if="!edit" color="secondary" icon @click="editUser(true)"><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn v-if="edit" text color="secondary" @click="editUser(false)">cancel edit</v-btn>
                <v-btn color="error" icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <h2 class="mb-3">User Info</h2>
                    <v-row>
                        <v-col>
                            <v-text-field
                                    label="First Name"
                                    :outlined="!edit"
                                    :value="user.first_name"
                                    :readonly="!edit"
                                    :append-icon="edit ? 'mdi-pencil':''"
                                    v-model="user.first_name"
                                    hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                    label="Last Name"
                                    :outlined="!edit"
                                    :value="user.last_name"
                                    :readonly="!edit"
                                    :append-icon="edit ? 'mdi-pencil':''"
                                    v-model="user.last_name"
                                    hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col lg="12">
                            <v-text-field
                                    label="Email address"
                                    :outlined="!edit"
                                    :value="user.email"
                                    :readonly="!edit"
                                    :append-icon="edit ? 'mdi-pencil':''"
                                    v-model="user.email"
                                    hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn outlined color="primary">Reset password</v-btn>
                        </v-col>
                    </v-row>
                    <h2 class="mt-6">Roles <v-icon right color="success">mdi-plus</v-icon></h2>
                    <v-row>
                        <v-col>
                            <v-list-item v-for="role in user.roles" :key="role.id">
                                <v-list-item-content>
                                    <v-list-item-title>{{role.name}}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-icon color="error">mdi-delete</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="error" @click="edit = !edit" v-if="edit">cancel</v-btn>
                <v-btn color="success" large tile width="150">{{updateButton}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import User from "../../../../models/user";
    export default {
        name: "viewUserDialog",
        data() {
            return{
                user: new User('', ''),
                dialog: false,
                resolve: null,
                reject: null,
                edit: false,
            }
        },
        computed: {
            updateButton() {
                return this.edit ? 'Update' : 'Close';
            }
        },
        watch: {
            dialog(dialog) {
                if (!dialog) {
                    this.edit = false;
                    this.close();
                }
            }
        },
        methods:{
            editUser(edit) {
                this.edit = edit;
            },
            open(user) {
                console.log(user);
                this.user = Object.assign({}, user);
                this.dialog = true;
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            close() {
                this.resolve(false);
                this.dialog = false;
                this.user = Object.assign({}, {});
            }
        }
    }
</script>

<style scoped>

</style>