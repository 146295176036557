<template>
  <div>
    <!-- <v-row>
         <v-col>
             <passwords :domain="getDomain.domain"></passwords>
         </v-col>
         <v-col>
             <notes :domain="getDomain.domain"></notes>
         </v-col>
         <v-col>
             <rates :domain="getDomain.domain"></rates>
         </v-col>
     </v-row>-->

    <!-- tool bar -->
    <v-row align="center" justify="start">
      <v-col>
        <v-card outlined>
          <v-card-text class="pa-0 ma-0">
            <v-toolbar flat color="transparent" dense class="pa-0 ma-0">
              <v-icon left>mdi-view-dashboard</v-icon>Dashboard
              <v-spacer/>
              <v-btn icon color="error"><v-icon>mdi-heart</v-icon></v-btn>
              <v-btn icon color="primary"><v-icon>mdi-printer</v-icon></v-btn>
            </v-toolbar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="start" justify="center">
      <v-col class="" cols="12" lg="8">
        <v-card height="100%" outlined>
          <v-card-text>
            <v-row align="center" justify="center">
              <!-- Active calls -->
              <v-col lg="4" cols="12">
                <div class="text-center">
                  <h3 class="body-1 mb-2 blue-grey--text">Active Calls</h3>
                  <h1 class="text-capitalize display-1 font-weight-light">{{ getDomain.active_call }}</h1>
                </div>
              </v-col>
              <!-- Policies -->
              <v-col lg="4" cols="12">
                <div class="text-center">
                  <h3 class="body-1 mb-2 blue-grey--text">Policies</h3>
                  <h1 class="text-capitalize display-1 font-weight-light">{{ getDomain.policies }}</h1>
                </div>
              </v-col>
              <!-- dial plan -->
              <v-col lg="4" cols="12">
                <div class="text-center">
                  <h3 class="body-1 mb-2 blue-grey--text">Dial Plan</h3>
                  <h1 class="display-1 font-weight-light">{{ getDomain.dial_plan }}</h1>
                </div>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="mt-6">
              <!-- moh -->
              <v-col lg="4" cols="12">
                <div class="text-center">
                  <h3 class="body-1 mb-2 blue-grey--text">MOH</h3>
                  <h1 class="text-capitalize display-1 font-weight-light">{{ getDomain.moh }}</h1>
                </div>
              </v-col>
              <!-- mor -->
              <v-col lg="4" cols="12">
                <div class="text-center">
                  <h3 class="body-1 mb-2 blue-grey--text">MOR</h3>
                  <h1 class="text-capitalize display-1 font-weight-light">{{ getDomain.mor }}</h1>
                </div>
              </v-col>
              <!-- mot -->
              <v-col lg="4" cols="12">
                <div class="text-center">
                  <h3 class="body-1 mb-2 blue-grey--text">MOT</h3>
                  <h1 class="text-capitalize display-1 font-weight-light">{{ getDomain.mot }}</h1>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4">
        <v-card height="100%" outlined>
          <v-card-text>
            <v-row class="fill-height" justify="center" align="center">
              <v-col col="3" class="text-center">
                <v-icon size="64" color="success" class="mb-6">mdi-check</v-icon>
                <p class="display-1 font-weight-light">Active</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <!--Remote Phonebook-->
      <v-col lg="6">
        <v-card tile outlined>
          <v-card-title><v-icon left>mdi-book-open</v-icon>Remote Phonebook</v-card-title>
          <v-card-text>
            <p>
              http://167.172.129.162/api/remote-phonebook?domain={{getDomain.domain}}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card tile outlined>
          <v-card-title><v-icon left>mdi-phone</v-icon>Numbers</v-card-title>
          <v-card-text>
            <v-toolbar flat color="transparent" class="mb-6">
              <v-spacer/>
              <v-text-field
                  placeholder="Search..."
                  filled
                  rounded
                  hide-details
                  single-line
                  append-icon="mdi-magnify"
              />
            </v-toolbar>
            <v-data-table>

            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!--<v-row>
        <v-col>
            <h1 class="grey&#45;&#45;text darken-4 mb-3">Bulk Editor</h1>
            <bulkEditor :domain="getDomain.domain"></bulkEditor>
        </v-col>
    </v-row>-->
  </div>
</template>

<script>
import passwords from "../components/passwords";
import notes from "../components/notes";
import rates from "../components/rates";
import bulkEditor from "../components/bulkEditor";

export default {
  name: "domainHome",
  // eslint-disable-next-line vue/no-unused-components
  components: {passwords, notes, rates, bulkEditor},
  data() {
    return{
      generatedPhonebookLink: ''
    }
  },
  computed: {
    getDomain() {
      return this.$store.getters["domains/getDomain"]
    }
  },
  created() {
    this.getPasswords()
    this.getRates()
    this.getNotes()
  },
  methods:{
    getPasswords() {
      this.$axios.get('/domains/passwords', {
        params: {
          domain: this.getDomain.domain
        }
      }).then(res => {
        this.$store.dispatch('domains/passwords/initDomainPasswords', res.data.data)
      })
    },
    getNotes() {
      this.$axios.get('/domains/notes', {
        params: {
          domain: this.getDomain.domain
        }
      }).then(res => {
        this.$store.dispatch('domains/notes/initDomainNotes', res.data.data)
      })
    },
    getRates() {
      this.$axios.get('/domains/rates', {
        params: {
          domain: this.getDomain.domain
        }
      }).then(res => {
        this.$store.dispatch('domains/rates/initDomainRates', res.data.data)
      })
    }
  }
}
</script>

<style scoped>

</style>