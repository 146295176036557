<template>
    <v-dialog v-model="dialog" width="650" scrollable>
        <v-card>
            <v-card-title>
                <v-icon left>mdi-note</v-icon>Viewing Note
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-textarea
                                rounded
                                filled
                                v-model="note.note"
                                readonly
                                color="primary"
                                height="500"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" icon large @click="deleteNote"><v-icon>mdi-delete</v-icon></v-btn>
                <v-spacer></v-spacer>
                <v-btn icon color="error" large @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import authHeader from "../../../../../services/auth-header";

    export default {
        name: "viewNote",
        data() {
            return {
                dialog: false,
                resolve: null,
                reject: null,
                note: {}
            }
        },
        watch: {
            dialog(value) {
                if (!value) {
                    this.note = Object.assign({}, {})
                }
            }
        },
        methods: {
            deleteNote() {
                this.$root.confirm.open({title: "Are you sure?", text: 'Are you sure you want to delete this note?'}).then(dialog=>{
                    if (dialog) {
                        this.$axios.delete('domains/notes',{
                            params:{
                                id: this.note.id
                            },
                            headers: authHeader()
                        }).then(()=>{
                            this.$axios.get('domains/notes',{
                                params:{
                                    domain: this.$store.getters["domains/getDomain"].domain
                                },
                                headers: authHeader()
                            }).then(res=>{
                                this.$store.dispatch('domains/notes/refreshNotes', res.data.data)
                                this.resolve(false)
                                this.dialog = false
                            })
                        }).catch(error=>{
                            console.log(error.response)
                            this.$root.tmSnackbar.open('error', 'mdi-alert', error.response)
                        })
                    }
                })
            },
            open(note) {
                this.note = Object.assign(this.note, note)
                this.dialog = true
                return new Promise((resolve, reject) => {
                    this.resolve = resolve
                    this.reject = reject
                })
            },
            close() {
                this.resolve(false)
                this.dialog = false
            }
        }
    }
</script>

<style scoped>

</style>