
export const notes = {
    namespaced: true,
    state: {
        notes: [],
    },
    getters: {
        getDomainNotes: (state) => {
            return state.notes
        },
    },
    actions: {
        initDomainNotes({commit}, data) {
            commit('initDomainNotes', data)
        },
        pushNotes({commit}, data) {
            commit('pushNotes', data)
        },
        refreshNotes({commit}, data) {
            commit('refreshNotes', data)
        }
    },
    mutations: {
        initDomainNotes(state, data){
            state.notes = data
        },
        pushNotes(state, data) {
            state.notes.push(data)
        },
        refreshNotes(state, data) {
            state.notes = []
            state.notes = data
        }
    },
}