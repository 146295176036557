<template>
    <v-app class="background">
        <router-view></router-view>
    </v-app>
</template>

<script>
    export default {
        name: "auth"
    }
</script>

<style scoped>
    .background{
        background-image: url("/images/backgrounds/misty_forest2.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
</style>