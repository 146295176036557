<template>
    <router-view></router-view>
</template>

<script>
    export default {
        name: "domains",
        created() {

        },
    }
</script>

<style scoped>

</style>