export const passwords = {
    namespaced: true,
    state: {
        passwords: [],
    },
    getters: {
        getDomainPasswords: (state) => {
            return state.passwords
        },
    },
    actions: {
        initDomainPasswords({commit}, data) {
            commit('initDomainPasswords', data)
        },
        pushDomainPasswords({commit}, data) {
            commit('pushDomainPasswords', data)
        },
        refreshDomainPasswords({commit}, data) {
            commit('refreshDomainPasswords', data)
        }
    },
    mutations: {
        refreshDomainPasswords(state, data) {
            state.passwords = []
            state.passwords = data
        },
        initDomainPasswords(state, data){
            state.passwords = data
        },
        pushDomainPasswords(state, data) {
            state.passwords.push(data)
        }
    }
}