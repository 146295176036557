<template>
    <v-content>
        <v-container class="fill-height">
            <v-row justify="center" align="center">
                <v-col cols="xs" sm="6" md="5" lg="3">
                    <v-card tile class="pa-6 trans-5">
                        <v-img src="/images/talkmanager_logo_blue_512.png" contain height="100"></v-img>
                        <h1 class="text-center font-weight-light py-4 white--text">Login to begin</h1>
                        <v-card-text>
                            <v-form ref="form" lazy-validation>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                                label="Email"
                                                placeholder=" "
                                                dark
                                                color="blue"
                                                v-model="user.email"
                                                :rules="[v=>!!v ||'An email is required']"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                                label="Password"
                                                type="password"
                                                placeholder=" "
                                                append-icon="mdi-eye"
                                                dark
                                                color="blue"
                                                v-model="user.password"
                                                :rules="[v=>!!v||'A password is required']"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                    block
                                    color="primary"
                                    height="55"
                                    dark
                                    @click="login"
                                    :loading="loading"
                            >login</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-snackbar v-model="showError" color="error" bottom>
                {{errorMessage}}
                <v-btn dark icon @click="showError = !showError">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-snackbar>
        </v-container>
    </v-content>
</template>

<script>
    import User from '../../models/user';

    export default {
        name: "login",
        data() {
            return{
                user: new User('', ''),
                email: '',
                password: '',
                showError: false,
                errorMessage: '',
                loading: false,
            }
        },
        methods:{
            login() {
                if (!this.$refs.form.validate()) {
                    this.errorMessage = 'Please check the login form!';
                    this.showError = true;
                    return;
                }
                this.loading = true;
                this.$store.dispatch('auth/login', this.user).then(
                    () => {
                        this.loading = false;
                        this.$router.push({name: 'auth.mfa'});
                    },
                    error => {
                        console.log(error);
                        this.loading = false;
                        this.showError = true;
                        if (!error.response) {
                            this.errorMessage = error + '. Could not reach the authentication server!';
                        } else {
                            this.errorMessage = error.response.data.message;
                        }
                    }
                );
            }
        }
    }
</script>

<style scoped>
    .trans-5{
        background-color: rgba(0, 0, 0, 0.5) !important;
    }
    .background{
        background-image: url("/images/backgrounds/misty_forest2.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
</style>