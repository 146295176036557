<template>
    <div>
        <v-card outlined hover tile>
            <v-card-title>
                <v-icon left>mdi-karate</v-icon>Rates <v-btn icon color="primary" @click="openNewRate"><v-icon>mdi-plus</v-icon></v-btn>
                <v-spacer></v-spacer>
                <v-text-field
                        label="Search rates..."
                        append-icon="mdi-magnify"
                        rounded
                        filled
                        hide-details
                        single-line
                        dense
                        v-model="search"
                ></v-text-field>
            </v-card-title>
            <v-card-text>
                <v-data-table
                        :headers="headers"
                        :items="getRates"
                        fixed-header
                        :search="search"
                >
                    <template v-slot:no-data>
                        <v-card flat color="transparent">
                            <v-card-text>
                                <v-avatar size="128" tile class="mb-3">
                                    <v-icon size="128">mdi-folder-alert-outline</v-icon>
                                </v-avatar>
                                <h3>There is nothing here....</h3>
                                <h4>Create something awesome!</h4>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <!--Dialogs-->
        <newRate ref="newRate" :domain="domain"></newRate>

    </div>
</template>

<script>
    import newRate from "./dialogs/newRate";

    export default {
        name: "rates",
        props: ['domain'],
        components: {newRate},
        data() {
            return{
                headers:[
                    {text: 'User Type', value: 'userType'},
                    {text: 'Cost', value: 'cost'},
                    {text: 'Actions', value: 'actions'}
                ],
                search: ''
            }
        },
        computed: {
            getRates() {
                return this.$store.getters["domains/rates/getDomainRates"]
            }
        },
        methods:{
            openNewRate() {
                this.$refs.newRate.open();
            }
        }
    }
</script>

<style scoped>

</style>