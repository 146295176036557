<template>
    <v-dialog v-model="dialog" width="450">
        <v-card>
            <v-toolbar flat>
                <v-icon left color="success">mdi-plus</v-icon> Add new user
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="close"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <v-row>
                        <v-col>
                            <h2 class="mb-6">User Info</h2>
                            <v-divider inset></v-divider>
                            <v-text-field
                                    label="First Name"
                                    outlined
                                    placeholder=" "
                                    v-model="user.first_name"
                                    :rules="rules"
                            ></v-text-field>
                            <v-text-field
                                    label="Last Name"
                                    outlined
                                    placeholder=" "
                                    v-model="user.last_name"
                                    :rules="rules"
                            ></v-text-field>
                            <v-text-field
                                    label="Email address"
                                    outlined
                                    placeholder=" "
                                    v-model="user.email"
                                    :rules="rules"
                            ></v-text-field>
                            <v-text-field
                                    label="Password"
                                    outlined
                                    placeholder=" "
                                    v-model="user.password"
                                    :rules="rules"
                            >
                                <template v-slot:append>
                                    <v-icon color="secondary" @click="generateRandomPassword">mdi-auto-fix</v-icon>
                                </template>
                            </v-text-field>
                            <h2 class="mt-6">Roles</h2>
                            <v-radio-group row v-model="user.roleId" :rules="[v=>!!v||'A default role must be selected']">
                                <v-radio label="User" value="1"></v-radio>
                                <v-radio label="Admin" value="2"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn icon color="success" @click="saveUser"><v-icon>mdi-check</v-icon></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import User from "../../../../models/user";
    import UsersRepository from "../../../../Repositories/UsersRepository";

    export default {
        name: "newUserDialog",
        data() {
            return{
                dialog: false,
                reslove: null,
                reject: null,
                user: new User(),
                rules: [v=>!!v||'This field is required!']
            }
        },
        methods:{
            generateRandomPassword() {
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                var charactersLength = characters.length;
                for ( var i = 0; i < 16; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                console.log(result);
                this.user.password = result;
            },
            saveUser() {
                if (!this.$refs.form.validate()) {
                    return;
                }
                UsersRepository.addUser({user: this.user, createdBy: this.$store.state.auth.user}).then(
                    res => {
                        console.log('Add User Reponse');
                        this.users.push(res.data);
                    }
                ).catch(error => {
                    console.log(error.response.data.message);
                });
            },
            open() {
                this.dialog = true;
                return new Promise((r, j) => {
                    this.resolve = r;
                    this.reject = j;
                });
            },
            close() {
                this.resolve(false);
                this.$refs.form.reset();
                this.dialog = false;
                this.user = new User();
            }
        }
    }
</script>

<style scoped>

</style>