import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import VueClipboard from 'vue-clipboard2'
console.log('Dev api url: ' + process.env.VUE_APP_API_URL);

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

Vue.use(require('vue-moment'));

import Toasted from 'vue-toasted';
Vue.use(Toasted, {
  duration: 3000,
  position: 'bottom-right'
})

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
