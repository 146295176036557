<template>
    <div>
        <v-row align="center" justify="space-around">
            <v-col lg="3">
                <v-card outlined>
                    <v-card-title>Domains <v-spacer></v-spacer> <v-btn icon class="ml-3" @click="refreshDomains"><v-icon>mdi-refresh</v-icon></v-btn></v-card-title>
                    <v-card-text>
                        <h1 class="display-2 font-weight-light">{{items.length}}</h1>
                        <v-divider class="my-2"></v-divider>
                        <span class="caption">Total</span>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col lg="3">
                <v-card outlined>
                    <v-card-title>Off-Net Calls <v-spacer></v-spacer> <v-btn icon class="ml-3" @click="refreshDomains"><v-icon>mdi-refresh</v-icon></v-btn></v-card-title>
                    <v-card-text>
                        <h1 class="display-2 font-weight-light">{{getTotalActiveCalls}}</h1>
                        <v-divider class="my-2"></v-divider>
                        <span class="caption">Total Across all Domains</span>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col lg="3">
                <v-card outlined>
                    <v-card-title>Most Subscribers <v-spacer></v-spacer> <v-btn icon class="ml-3" @click="refreshDomains"><v-icon>mdi-refresh</v-icon></v-btn></v-card-title>
                    <v-card-text>
                        <h1 class="display-2 font-weight-light">{{getMostSubscribers.count}}</h1>
                        <v-divider class="my-2"></v-divider>
                        <span class="caption">{{getMostSubscribers.domainName}}</span>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col lg="3">
                <v-card outlined>
                    <v-card-title>Most Aactive Calls <v-spacer></v-spacer> <v-btn icon class="ml-3" @click="refreshDomains"><v-icon>mdi-refresh</v-icon></v-btn></v-card-title>
                    <v-card-text>
                        <h1 class="display-2 font-weight-light">{{getDomainWithMostActiveCalls.count}}</h1>
                        <v-divider class="my-2"></v-divider>
                        <span class="caption">{{getDomainWithMostActiveCalls.domainName}}</span>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <div class="body-1">*Stats refresh every 5 seconds</div>

        <h2 class="mt-12"><v-icon left color="error">mdi-heart</v-icon>My Favorites</h2>
        <v-row>
            <v-col>
                <v-data-iterator
                        :items="favorites"
                >
                    <template v-slot:no-data>
                        <v-card flat color="transparent">
                            <v-card-text class="text-center">
                                <v-icon size="128" color="error">mdi-heart-broken</v-icon>
                                <h3>You have no favorites :(</h3>
                                <h4>Go favorite something!</h4>
                            </v-card-text>
                        </v-card>
                    </template>
                    <template v-slot:default="props">
                        <v-row>
                            <v-col
                                    v-for="item in props.items"
                                    :key="item.domain"
                                    cols="12"
                                    lg="2"
                            >
                                <v-card @click="gotoDomain(item)">
                                    <v-card-text class="text-center">
                                        <v-icon color="error" size="64">mdi-heart</v-icon>
                                        <h3 class="mt-3">{{item.domain}}</h3>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-iterator>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import NetSapiensService from "../../../services/netsapiens.service";
    export default {
        name: "dashboard",
        data() {
            return{
                items: [],
                setInterval: null,
            }
        },
        created() {
            this.getFavorites()
        },
        beforeMount() {
            NetSapiensService.getDomains().then(res => {
                this.items = res.data.data;
            });
            /*this.setInterval = setInterval(() => {
                this.refreshDomains();
            }, 5000);*/
        },
        beforeDestroy() {
            clearInterval(this.setInterval);
            this.setInterval = null;
        },
        computed: {
            favorites() {
                return this.$store.getters["domains/favorites/getFavorites"]
            },
            getTotalActiveCalls() {
                let count = 0;
                if (this.items.length === 0) {
                    return count;
                }
                this.items.forEach((domain) => {
                    if (domain.domain === 'integritalk.net') {
                        console.log(domain);
                    }
                    if (domain.countExternal > 0) {
                        count = count + domain.countExternal;
                    }
                });
                return count;
            },
            getMostSubscribers() {
                let count = 0;
                let domainName = 'Not set';
                this.items.forEach((domain) => {
                    if (domain.sub_count > count) {
                        count = domain.sub_count;
                        domainName = domain.domain;
                    }
                });
                return {count: count, domainName: domainName};
            },
            getDomainWithMostActiveCalls() {
                let count = 0;
                let domainName = 'None right now';
                this.items.forEach((domain) => {
                    if (domain.countExternal > count) {
                        count = domain.countExternal;
                        domainName = domain.domain;
                    }
                });
                return {count:count, domainName:domainName}
            }
        },
        methods:{
            gotoDomain(domain) {
                this.$router.push({name: 'domain.view', params:{domain: domain.domain}});
            },
            getFavorites() {
                this.$axios.get('domains/favorites').then(res=>{
                    console.warn('get favorites')
                    console.log(res)
                    this.$store.dispatch('domains/favorites/initFavorites', res.data.data)
                }).catch(error=>{
                    this.$root.tmSnackbar.open('error', 'mdi-alert', error.response.data.message)
                })
            },
            refreshDomains() {
                NetSapiensService.getDomains().then(res => {
                    this.items = [];
                    this.items = res.data;
                });
            }
        }
    }
</script>

<style scoped>

</style>