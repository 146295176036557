<template>
    <div class="px-3">

        <!--Toolbar-->
        <v-toolbar v-if="legacyAccount" class="mt-3" tile flat>
            Legacy Account Info <span class="font-italic ml-3 subtitle-2"> - Read only!</span>
            <v-spacer></v-spacer>
            <v-btn color="primary" tile class="mr-3" :href="`https://${legacyAccount.dns}`" target="_blank">Open freePBX</v-btn>
        </v-toolbar>

        <!--If account not linked-->
        <v-row v-if="legacyAccount === null" class="mt-12" align="center" justify="center">
            <v-col lg="6">
                <v-card outlined color="error" dark>
                    <v-card-text>
                        <p class="text-center">
                            <v-icon size="164">mdi-alert-decagram-outline</v-icon>
                        </p>
                        <h1 class="text-center display-1">No Account Linked!</h1>
                        <v-divider class="my-6"></v-divider>
                        <p>
                            There is no legacy account tied to this account.
                        </p>
                        <p>
                            Select from the the drop down list below to tie the account.
                        </p>
                        <p class="mt-6">
                            <v-autocomplete
                                    outlined
                                    rounded
                                    hide-details
                                    single-line
                                    :items="legacyAccounts"
                                    item-text="accountName"
                                    item-value="id"
                                    v-model="selectedAccount"
                                    clearable
                            ></v-autocomplete>
                        </p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" width="150" @click="LinkAccount" :disabled="!linkButtonEnabled">LINK!</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <!--If account linked-->
        <v-row v-else>
            <!--Info & Notes-->
            <v-col lg="4">
                <!--Info-->
                <v-row>
                    <v-col>
                        <v-card tile outlined>
                            <v-card-title><v-icon left color="purple">mdi-office-building</v-icon>{{legacyAccount.accountName}}</v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <v-list>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{legacyAccount.accountAddress !== '' ? legacyAccount.accountAddress:'Not saved'}}</v-list-item-title>
                                                    <v-list-item-subtitle>Account Address</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col>
                                        <v-list>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title :class="legacyAccount.currentClient ? 'green--text':'red--text'">{{legacyAccount.currentClient ? 'Yes':'No'}}</v-list-item-title>
                                                    <v-list-item-subtitle>Current Client?</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <!--Notes-->
                <v-row>
                    <v-col>
                        <v-card tile outlined>
                            <v-card-title><v-icon left color="primary">mdi-notebook</v-icon>Notes</v-card-title>
                            <v-card-text>
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-textarea dark outlined readonly :value="legacyAccount.accountNotes"></v-textarea>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <!--Network & Passwords-->
            <v-col lg="8" >
                <!--Network-->
                <v-row>
                    <v-col>
                        <v-card tile outlined>
                            <v-card-text>
                                <h2><v-icon left color="primary">mdi-network</v-icon>Network</h2>
                                <v-row>
                                    <v-col>
                                        <v-list>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{legacyAccount.internalIp}}</v-list-item-title>
                                                    <v-list-item-subtitle>Internal IP</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{legacyAccount.dns}}</v-list-item-title>
                                                    <v-list-item-subtitle>DNS</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col>
                                        <v-list>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{legacyAccount.externalIp}}</v-list-item-title>
                                                    <v-list-item-subtitle>External IP</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{legacyAccount.parentSMBNumber !== 0 ? legacyAccount.parentSMBNumber:'No'}}</v-list-item-title>
                                                    <v-list-item-subtitle>SMB?</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <!--Passwords-->
                <v-row>
                    <v-col>
                        <v-card tile outlined>
                            <v-card-text>
                                <h2><v-icon left color="primary">mdi-key</v-icon>Passwords</h2>
                                <v-row>
                                    <v-col lg="4">
                                        <v-list>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{legacyAccount.rootPassword}}</v-list-item-title>
                                                    <v-list-item-subtitle>Root Password</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{legacyAccount.asteriskManagerPassword}}</v-list-item-title>
                                                    <v-list-item-subtitle>Asterisk Manager Password</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{legacyAccount.adminPortalPassword}}</v-list-item-title>
                                                    <v-list-item-subtitle>Admin Portal Password</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{legacyAccount.webUIPassword}}</v-list-item-title>
                                                    <v-list-item-subtitle>FreePBX Password</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{legacyAccount.phonePassword ? legacyAccount.phonePassword:'-- Nothing saved --'}}</v-list-item-title>
                                                    <v-list-item-subtitle>Phone Password</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col lg="8">
                                        <v-subheader>Phone Passwords</v-subheader>
                                        <v-data-table
                                                :headers="[{text: 'Extension', value: 'extension'},{text: 'Password', value: 'password'}]"
                                                :items="legacyAccountPhonePasswords"
                                                hide-default-footer
                                                disable-pagination
                                        ></v-data-table>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

    </div>
</template>

<script>
    export default {
        name: "domainLegacy",
        data() {
            return{
                legacyAccount:null,
                legacyAccountPhonePasswords: [],
                domains: [],
                selectedAccount: null,
                legacyAccounts: [],
                linkButtonEnabled: false,
            }
        },
        watch: {
            selectedAccount() {
                this.linkButtonEnabled = this.selectedAccount
            }
        },
        mounted() {
            this.GetLegacyAccountInfo()
            this.GetDomains()
        },
        methods:{
            LinkAccount() {
                if(!this.selectedAccount){
                    this.$root.tmSnackbar.open('error', 'mdi-alert', 'You must select a domain for the drop down list!')
                    return
                }
                this.$axios.post('legacy/phonemanager/link-account', {
                    domain: this.$store.getters["domains/getDomain"].domain,
                    legacyAccountId: this.selectedAccount
                }).then(res=>{
                    this.$root.tmSnackbar.open('success', 'mdi-check', res.data.message)
                    this.GetLegacyAccountInfo()
                }).catch(error=>{
                    this.$root.tmSnackbar.open('error', 'mdi-alert', error.response.data.message)
                })
            },
            GetLegacyAccountInfo() {
                this.legacyAccounts = []
                this.legacyAccount = null
                this.$axios.get('legacy/phonemanager/accounts').then(res => {
                    res.data.forEach(account=>{
                        if(account.domain)
                            if(account.domain === this.$store.getters["domains/getDomain"].domain){
                                this.legacyAccount = account
                                this.GetAccountPhonePasswords()
                            }
                    })
                    this.legacyAccounts = res.data
                }).catch(error=>{
                    this.$root.tmSnackbar.open('error', 'mdi-alert', error.response.data.message)
                })
            },
            GetDomains() {
                this.$axios.get('netsapiens/domains').then(res=>{
                    this.domains = res.data
                }).catch(error=>{
                    this.$root.tmSnackbar.open('error', 'mdi-alert', error.response.data.message)
                })
            },
            GetAccountPhonePasswords() {
                this.$axios.get('legacy/phonemanager/account/phone-passwords', {
                    params: {accountId: this.legacyAccount.accountId}
                })
                    .then(res => {
                        this.legacyAccountPhonePasswords = res.data
                    })
                    .catch(error => {
                        this.$root.tmSnackbar.open('error', 'mdi-alert', error.response.data.message)
                    })
            }
        }
    }
</script>

<style scoped>

</style>