import {passwords} from './passwords.module'
import {notes} from './notes.module'
import {rates} from './rates.module'
import {favorites} from "./favorites";

export const domains = {
    namespaced: true,
    modules: {
        passwords,
        notes,
        rates,
        favorites
    },
    state:{
        domain: ''
    },
    getters: {
        getDomain: (state) =>{
            return state.domain
        }
    },
    actions: {
        setDomain({commit}, data){
            commit('setDomain', data)
        }
    },
    mutations: {
        setDomain(state, data) {
            state.domain = data
        }
    }
}