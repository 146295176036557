import Vue from 'vue'
import VueRouter from 'vue-router'
import dashboard from "../views/pages/dashboard/dashboard";
// eslint-disable-next-line no-unused-vars
import domains from "../views/pages/domains/domains";
// eslint-disable-next-line no-unused-vars
import viewDomain from "../views/pages/domains/viewDomain";
import auth from "../views/auth/auth";
import login from "../views/auth/login";
import mfa from "../views/auth/mfa";
import administration from "../views/pages/administration/administration";
import administrationHome from "../views/pages/administration/administrationHome";
import users from "../views/pages/administration/users/users";
// eslint-disable-next-line no-unused-vars
import domainsHome from "../views/pages/domains/pages/domainsHome";
import AppHome from "../views/pages/AppHome/AppHome";
import integriTalk from "../views/pages/domains/pages/integriTalk";
import domainUsers from "../views/pages/domains/pages/domainUsers";
import domainHome from "../views/pages/domains/pages/domainHome";
import domainLegacy from "../views/pages/domains/pages/domainLegacy";

Vue.use(VueRouter)

  const routes = [
      /*
      *   Auth
      * */
    {
      path: '/auth',
      name: 'auth',
      component: auth,
      children:[
        {
          path: 'login',
          name: 'auth.login',
          component: login,
          meta: [
            {title: 'Login', requiresAuth: false}
          ]
        },
        {
          path: 'mfa',
          name: 'auth.mfa',
          component: mfa,
          meta: [
            {title: '2FA', requiresAuth: false}
          ]
        },
      ]
    },


    /*
    *   Main
    * */
    {
      path: '/app',
      name: 'app',
      component: AppHome,
      children: [
        {
          path: 'dashboard',
          name: 'dashboard',
          component: dashboard,
        },
        {
          path: 'administration',
          name: 'administration',
          component: administration,
          children:[
            {
              path: 'home',
              name: 'administration.home',
              component: administrationHome,
            },
            {
              path: 'users',
              name: 'administration.users',
              component: users,
            }
          ]
        },
        {
          path: 'acrobits',
          name: 'acrobits',
          component: () => import('../views/pages/acrobits/acrobits'),
          children: [

          ]
        },
        {
          path: 'domains',
          name: 'domains',
          component: domains,
          children: [
            {
              path: 'home',
              name: 'domains.home',
              component: domainsHome
            },
            {
              path: ':domain',
              name: 'domain.view',
              component: viewDomain,
              props: true,
              children:[
                {
                  path: 'home',
                  name: 'domain.home',
                  component: domainHome,
                  props: true
                },
                {
                  path: 'users',
                  name: 'domain.users',
                  component: domainUsers
                },
                {
                  path: 'legacy',
                  name: 'domain.legacy',
                  component: domainLegacy
                },
                {
                  path: 'acrobits/:domain',
                  name: 'domain.acrobits',
                  props: true,
                  component: () => import('../views/pages/domains/pages/acrobits/root')
                }
              ]
            },
            {
              path: ':domain/integritalk',
              name: 'domain.integritalk',
              component: integriTalk,
              props: true,
            }
          ]
        },
      ]
    },
    /*{
      path: '/about',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      //component: () => import(/!* webpackChunkName: "about" *!/ '../views/About.vue')
    }*/
  ];

const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
