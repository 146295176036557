<template>
    <v-dialog v-model="dialog" width="800" persistent scrollable>
        <v-card>
            <v-card-title>
                Upload a CSV for global import
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <v-row>
                        <v-col>
                            <v-file-input v-model="file" accept="text/csv" :rules="[v=>!!v || 'A file is required!']"></v-file-input>
                            <v-btn color="primary" :disabled="!file" @click="UploadFile"><v-icon left>mdi-upload</v-icon>Upload</v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="results">
                        <v-col>
                            <!--Error-->
                            <v-alert v-model="error" color="error" tile outlined>
                                <p v-html="errorMessage.replace(/(?:\r\n|\r|\n)/g, '<br />')" class="mb-0 pb-0"></p>
                            </v-alert>
                            <v-text-field
                                    label="Search..."
                                    append-icon="mdi-magnify"
                                    clearable
                                    rounded
                                    filled
                                    dense
                                    single-line
                                    hide-details
                                    v-model="search"
                                    class="mb-3"
                            ></v-text-field>
                            <v-data-table
                                    :headers="headers"
                                    :items="items"
                                    :search="search"
                                    dense
                                    fixed-header
                                    height="300"
                            ></v-data-table>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-tooltip fixed top content-class="white tooltip-op">
                    <template v-slot:activator="{on}">
                        <v-btn color="purple" v-on="on" @click="DownloadGlobalContactsTemplate"><v-icon left>mdi-download</v-icon>Template</v-btn>
                    </template>
                    <v-row align="center" justify="center" class="text-center">
                        <v-col>
                            <v-icon size="64" color="primary">mdi-help</v-icon>
                            <p class="blue-grey--text">
                                Download a CSV template for easy import! <br>
                                Otherwise, the importer will not work correctly.
                            </p>
                            <p>
                                <v-img src="https://thumbs.gfycat.com/CorruptGaseousEquestrian-size_restricted.gif" height="100" contain></v-img>
                            </p>
                            <p class="blue-grey--text">I am not a wizard.</p>
                        </v-col>
                    </v-row>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn color="success" icon @click="confirm" :disabled="!contactsValidated">
                    <v-icon>mdi-check</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    // eslint-disable-next-line no-unused-vars
    import authHeader from "../../../../../services/auth-header";

    const Papa = require('papaparse')
    export default {
        name: "globalContactCSVDialog",
        props:['users'],
        data() {
            return {
                headers: [
                    {text: 'Extension', value: 'user'},
                    {text: 'First Name', value: 'first_name'},
                    {text: 'Last Name', value: 'last_name'},
                    {text: 'Cell', value: 'cell_phone'},
                    {text: 'Work', value: 'work_phone'},
                    {text: 'Home', value: 'home_phone'}
                ],
                items: [],
                search: '',
                results: null,
                file: null,
                dialog: false,
                resolve: null,
                reject: null,
                error: false,
                errorMessage: '',
                contactsValidated: false,
            }
        },
        watch: {
            dialog(value) {
                if (!value) {
                    this.$refs.form.reset()
                }
            },
            file(value) {
                if (!value) {
                    this.results = null;
                    this.items = [];
                }
            }
        },
        methods: {
            confirm() {
                if (!this.$refs.form.validate()) return;
                if (!this.results) return;
                if (this.items.length === 0) return;
                if (!this.contactsValidated) return;

                this.$axios.post('domains/global-contacts', {
                    domain: this.$store.getters['domains/getDomain'].domain,
                    contacts: this.items
                }, {headers: authHeader()})
                    .then(res => {
                        console.log(res)
                    })
                    .catch(error => {
                        console.error(error);
                    });
            },
            UploadFile() {
                if (!this.$refs.form.validate()) return;
                if (this.results) return;
                if (this.items.length > 0) return;

                // eslint-disable-next-line no-unused-vars
                let self = this

                Papa.parse(this.file, {
                    header: true,
                    // eslint-disable-next-line no-unused-vars
                    complete: function (results, file) {
                        self.results = results;
                        self.items = results.data;

                        let nameCheck = true;
                        let changedObjects = []

                        for (const check of results.data) {
                            let source = self.users.find((t) => t.user === check.user);
                            if(!source) break;

                            if(check.first_name !== source.first_name){
                                nameCheck = false;
                                changedObjects.push({
                                    user: source.user,
                                    changed: 'first_name',
                                    source: source.first_name,
                                    check: check.first_name
                                })
                            } else if(check.last_name !== source.last_name){
                                nameCheck = false;
                                changedObjects.push({
                                    user: source.user,
                                    changed: 'last_name',
                                    source: source.last_name,
                                    check: check.last_name
                                })
                            }

                        }

                        if(!nameCheck){
                            self.error = true;
                            let message = 'The names do not match!\r\n';
                            for (const co of changedObjects) {
                                message += `${co.user}'s <strong>${co.changed}</strong> has been changed from <strong>${co.source}</strong> to <strong>${co.check}</strong>. \r\n Do not change the names!`
                            }
                            self.errorMessage = message;
                        }else self.contactsValidated = true;
                    }
                });
            },
            open() {
                this.dialog = true
                return new Promise((resolve, reject) => {
                    this.resolve = resolve
                    this.reject = reject
                })
            },
            close() {
                this.resolve(false)
                this.dialog = false
            },
            DownloadGlobalContactsTemplate() {
                let csv = 'user,first_name,last_name,cell_phone,work_phone,home_phone,email\n';
                for (const user of this.users) {
                    console.log(user)
                    csv += `${user.user},${user.first_name},${user.last_name}\n`
                }
                const anchor = document.createElement('a');
                anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
                anchor.target = '_blank';
                anchor.download = `${this.$store.getters['domains/getDomain'].domain}_global_contacts_template.csv`;
                anchor.click();
            }
        }
    }
</script>

<style scoped>
    .tooltip-op{
        opacity: 1 !important;
    }
</style>