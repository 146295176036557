<template>
    <v-snackbar
            v-model="dialog"
            :color="color"
            bottom
            right
    >
        <h3 class="font-weight-light"><v-icon left dark>{{icon}}</v-icon>{{message}}</h3>
    </v-snackbar>
</template>

<script>
    export default {
        name: "tmSnackbar",
        data() {
            return{
                dialog: false,
                color: 'success',
                icon: 'mdi-check',
                message: 'Forgot to set message'
            }
        },
        watch:{
            dialog(value) {
                if (!value) {
                    this.color = 'success'
                    this.icon = 'mdi-check'
                    this.message = 'Forgot to set message'
                }
            }
        },
        methods: {
            open(color, icon, message) {
                this.color = color
                this.icon = icon
                this.message = message
                this.dialog = true
            }
        }
    }
</script>

<style scoped>

</style>