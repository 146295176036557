<template>
  <div>
    <v-navigation-drawer
        absolute
        mini-variant
        clipped
    >
      <v-list flat>
        <!-- Home -->
        <v-list-item color="primary" :to="{name: 'domain.home'}">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>asdf</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Legacy -->
        <v-list-item color="primary" :to="{name: 'domain.legacy'}">
          <v-list-item-icon>
            <v-icon>mdi-cupcake</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>asdf</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Acrobits -->
        <v-list-item color="primary" :to="{name: 'domain.acrobits'}">
          <v-list-item-icon>
            <v-icon>mdi-cellphone-settings</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>asdf</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-container class="px-6">
      <v-toolbar flat color="transparent" class="pa-0 ma-0" dense>
        <h1 class="display-1 font-weight-light">{{$store.getters["domains/getDomain"].description}}</h1>
        <v-spacer></v-spacer>
      </v-toolbar>
      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "viewDomain",
  props:['domain'],
  data() {
    return{

    }
  },
  computed: {
    isFavorite() {
      //return this.$store.getters["domains/favorites/isFavorite"](this.$store.getters["domains/getDomain"].domain)
      return false
    }
  },
  created() {
    this.getDomain()
  },
  methods:{
    favoriteDomain() {
      if (this.isFavorite) {
        let fav = this.$store.getters["domains/favorites/getFavorite"](this.$store.getters["domains/getDomain"].domain)
        this.$axios.delete('domains/favorites',{
          params:{
            id: fav.id
          },
        }).then(success=>{
          this.$root.tmSnackbar.open('success', 'mdi-check', success.data.message)
          this.$axios.get('domains/favorites',{
            params:{
              UserId: this.$store.state.auth.user.id
            },
          }).then(res=>{
            this.$store.dispatch('domains/favorites/refreshFavorite', res.data.data)
          }).catch(error=>{
            this.$root.tmSnackbar.open('error', 'mdi-alert', error.response.data.message)
          })
        }).catch(error=>{
          this.$root.tmSnackbar.open('error', 'mdi-alert', error.response.data.message)
        })
      }else{
        this.$axios.post('domains/favorites',{domain: this.$store.getters["domains/getDomain"].domain, UserId: this.$store.state.auth.user.id},{
        }).then(res=>{
          this.$root.tmSnackbar.open('success', 'mdi-check', res.data.message)
          this.$store.dispatch('domains/favorites/pushFavorite', res.data.data)
        }).catch(error=>{
          this.$root.tmSnackbar.open('error', 'mdi-alert', error.response.data.message)
        })
      }
    },
    getDomain() {
      this.$axios.get('netsapiens/domain', {
        params: {
          domain: this.domain
        },
      }).then(res => {
        this.$store.dispatch('domains/setDomain', res.data.data[0])
        this.$router.push({name: 'domain.home'})
      }).catch(error=>{
        this.$toasted.show(error.response, {type: 'error'})
      })
    },
  }
}
</script>

<style scoped>

</style>