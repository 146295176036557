<template>
    <div>
        <v-toolbar flat color="transparent">
            <v-btn icon color="primary" @click="addUser"><v-icon>mdi-plus</v-icon></v-btn>
            <v-spacer/>
            <v-text-field
                    placeholder="Search..."
                    append-icon="mdi-magnify"
                    filled
                    rounded
                    dense
                    v-model="search"
                    class="mb-3"
                    hide-details
            />
        </v-toolbar>
        <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                dense
        >
            <!--Actions-->
            <template v-slot:item.actions="{item}">
                <v-btn icon color="warning" @click="editUser(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn icon color="error" @click="deleteUser(item)"><v-icon>mdi-delete</v-icon></v-btn>
            </template>
        </v-data-table>

        <!--Add User Dialog-->
        <addUserDialog ref="addUserDialog"></addUserDialog>
    </div>
</template>

<script>
    import authHeader from "../../../../services/auth-header";
    import addUserDialog from '../components/addUserDialog'
    export default {
        name: "usersTabItem",
        components: {
            addUserDialog
        },
        data() {
            return{
                headers: [
                    {text: 'First name', value: 'first_name'},
                    {text: 'Last name', value: 'last_name'},
                    {text: 'Email', value: 'email'},
                    {text: 'Actions', value: 'actions', align: 'center'}
                ],
                items: [],
                search: ''
            }
        },
        beforeMount() {
            this.GetUsers()
        },
        methods:{
            GetUsers() {
                if(this.items.length > 0) this.items = []
                this.$axios.get('/users', {headers: authHeader()}).then(res=>{
                    this.items = res.data
                })
            },
            addUser() {
                this.$refs.addUserDialog.open().then(res=>{
                    if(res)
                        this.GetUsers()
                })
            },
            editUser(user) {
                console.log(user)
            },
            deleteUser(user) {
                console.log(user)
            }
        }
    }
</script>

<style scoped>

</style>