<template>
    <v-dialog v-model="dialog" width="500">
        <v-card>
            <v-card-title>
                Add User
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-form ref="form" lazy-validation>
                            <v-text-field
                                    label="First name"
                                    filled
                                    rounded
                                    hide-details
                                    v-model="newUser.first_name"
                                    class="mb-3"
                            />
                            <v-text-field
                                    label="Last name"
                                    filled
                                    rounded
                                    hide-details
                                    v-model="newUser.last_name"
                                    class="mb-3"
                            />
                            <v-text-field
                                    label="Email"
                                    filled
                                    rounded
                                    hide-details
                                    v-model="newUser.email"
                            />
                        </v-form>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer>

                </v-spacer>
                <v-btn icon color="error" @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn color="success" icon @click="addUser">
                    <v-icon>mdi-check</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import authHeader from "../../../../services/auth-header";
    export default {
        name: "addUserDialog",
        data() {
            return {
                dialog: false,
                resolve: null,
                reject: null,
                newUser: {
                    first_name: '',
                    last_name: '',
                    email: '',
                }
            }
        },
        watch: {
            dialog(value) {
                if (!value) {
                    this.$refs.form.reset()
                    this.newUser = {
                        first_name: '',
                        last_name: '',
                        email: '',
                    }
                }
            }
        },
        methods: {
            addUser() {
                if(this.newUser.name === '') return
                if(this.newUser.email === '') return
                this.$axios.post('users', this.newUser, {headers: authHeader()}).then(()=>{
                    this.$toasted.show('User has been created!', {type: 'success'})
                    this.resolve(true)
                    this.dialog = false
                }).catch(error=>{
                    this.$toasted.show(error.response.data.message, {type: 'error'})
                })
            },
            open() {
                this.dialog = true
                return new Promise((resolve, reject) => {
                    this.resolve = resolve
                    this.reject = reject
                })
            },
            close() {
                this.resolve(false)
                this.dialog = false
            }
        }
    }
</script>

<style scoped>

</style>