<template>
    <v-content>
        <v-container class="fill-height">
            <v-row align="center" justify="center">
                <v-col cols="xs" sm="5" md="5" lg="3">
                    <v-card tile class="pa-6 trans-5">
                        <v-tooltip fixed left>
                            <template v-slot:activator="{on}">
                                <v-btn fab color="purple" small v-on="on" @click="back"><v-icon>mdi-keyboard-backspace</v-icon></v-btn>
                            </template>
                            <v-icon left color="secondary">mdi-keyboard-backspace</v-icon> Back to login
                        </v-tooltip>
                        <v-img src="/images/talkmanager_2fa_512.png" contain height="150"></v-img>
                        <h2 class="font-weight-light text-center white--text">Enter your 2fa code</h2>
                        <p class="text-center white--text">Check your email for the 2fa code!</p>
                        <v-card-text>
                            <v-form>
                                <v-row>
                                    <v-col>
                                        <v-form ref="form" lazy-validation>
                                            <v-text-field
                                                    label="2fa Code"
                                                    placeholder=" "
                                                    color="blue"
                                                    dark
                                                    v-model="mfaCode"
                                                    :rules="[v=>!!v||'You must enter your 2fa code!']"
                                            ></v-text-field>
                                        </v-form>
                                        <v-btn block color="primary" x-large tile class="mt-6" :loading="loading" @click="verify">submit</v-btn>
                                        <v-btn text color="purple" block class="mt-6">resend code</v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-snackbar color="error" v-model="showError" bottom>
            {{errorMessage}}
            <v-btn dark icon @click="showError = !showError">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-content>
</template>

<script>
    export default {
        name: "mfa",
        data() {
            return{
                mfaCode: '',
                loading: false,
                showError: false,
                errorMessage: '',
            }
        },
        methods:{
            back() {
                this.$store.dispatch('auth/logout');
                this.$router.push({name: 'auth.login'});
            },
            verify() {
                if (!this.$refs.form.validate()) {
                    return;
                }
                this.loading = true;
                this.$store.dispatch('auth/confirmMFA', {mfaCode: this.mfaCode, userId:this.$store.state.auth.userId}).then(
                    () => {
                        this.loading = false;
                        this.$router.push({name: 'app'})
                    },
                    error => {
                        this.loading = false;
                        this.showError = true;
                        this.errorMessage = error.response.data.message
                    }
                );
            }
        }
    }
</script>

<style scoped>
    .trans-5{
        background-color: rgba(0, 0, 0, 0.5) !important;
    }
</style>