<template>
    <v-dialog v-model="dialog" width="650">
        <v-card>
            <v-card-title>
                Add A New Single Contact
            </v-card-title>
            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <v-row>
                        <v-col>
                            <v-text-field
                                    label="First Name"
                                    filled
                                    rounded
                                    v-model="newContact.firstName"
                                    :rules="[v=>!!v || 'First name is required']"
                            ></v-text-field>
                            <v-text-field
                                    label="Last Name"
                                    filled
                                    rounded
                                    v-model="newContact.lastName"
                                    :rules="[v=>!!v || 'Last name is required']"
                            ></v-text-field>
                            <v-text-field
                                    label="Work Phone"
                                    filled
                                    rounded
                                    v-model="newContact.workPhone"
                                    type="number"
                            ></v-text-field>
                            <v-text-field
                                    label="Home Phone"
                                    filled
                                    rounded
                                    v-model="newContact.homePhone"
                                    type="number"
                            ></v-text-field>
                            <v-text-field
                                    label="Cell Phone"
                                    filled
                                    rounded
                                    v-model="newContact.cellPhone"
                                    type="number"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn color="success" icon @click="saveContact">
                    <v-icon>mdi-check</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "dialogAddSingleContact",
        data() {
            return {
                dialog: false,
                resolve: null,
                reject: null,
                newContact:{
                    firstName: '',
                    lastName: '',
                    workPhone: '',
                    homePhone: '',
                    cellPhone: '',
                }
            }
        },
        watch: {
            dialog(value) {
                if (!value) {
                    this.newContact = {
                        firstName: '',
                        lastName: '',
                        workPhone: '',
                        homePhone: '',
                        cellPhone: '',
                    }
                    this.$refs.form.reset()
                }
            }
        },
        methods: {
            saveContact() {
                if (!this.$refs.form.validate()) {
                    return
                }
                this.resolve({
                    success: true,
                    data: this.newContact
                })
                this.dialog = false
            },
            open() {
                this.dialog = true
                return new Promise((resolve, reject) => {
                    this.resolve = resolve
                    this.reject = reject
                })
            },
            close() {
                this.resolve(false)
                this.dialog = false
            }
        }
    }
</script>

<style scoped>

</style>