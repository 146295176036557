import http from '../plugins/axios';
import authJwt from '../services/auth-header';

class UsersRepository{
    getUsers() {
        return http.get('/users', {headers: authJwt()});
    }

    addUser(data) {
        return http.post('/users', {user:data.user,createdBy:data.createdBy}, {headers: authJwt()});
    }

    updateUser(user) {
        return http.put('/users', {user: user}, {headers: authJwt()});
    }

    deleteUser(user) {
        return http.delete(`/users/${user.id}`, {headers: authJwt()});
    }

    resetPassword(user) {
        return http.post('/users/resetPassword', {user:user}, {headers: authJwt()});
    }
}

export default new UsersRepository();
