<template>
    <div>
        <v-toolbar flat>
            <v-toolbar-title>Administration</v-toolbar-title>
        </v-toolbar>
        <!--tabs-->
        <v-tabs>
            <!--Users-->
            <v-tab>Users</v-tab>
            <v-tab-item class="pa-3">
                <usersTabItem></usersTabItem>
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
    import usersTabItem from "./components/usersTabItem";
    export default {
        name: "administration",
        components: {
            usersTabItem
        },
        data() {
            return{
                pages: [
                    {title: 'Home', routerTo:'administration.home', icon: 'mdi-view-dashboard'},
                    {title: 'Users', routerTo:'administration.users', icon: 'mdi-account'}
                ],
            }
        },
        beforeMount() {
            this.$router.push({name: 'administration.home'});
        },
        computed: {
            getLoggedInUser() {
                console.log('---user---')
                console.log(this.$store.state.auth.user)
                return this.$store.state.auth.user;
            }
        }
    }
</script>

<style scoped>

</style>