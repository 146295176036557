<template>
    <v-dialog v-model="dialog" width="450">
        <v-card>
            <v-card-title>
                <h3><v-icon left color="yellow darken-1">mdi-comment-question-outline</v-icon>{{title}}</h3>
            </v-card-title>
            <v-card-text class="headline font-weight-light">{{message}}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="error" @click="close">cancel</v-btn>
                <v-btn tile color="success" @click="confirm" width="150">Yes!</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "confirmDialog",
        data() {
            return{
                dialog: false,
                resolve: null,
                reject: null,
                title: '',
                message: '',
            }
        },
        watch: {
            dialog(value) {
                if (!value) {
                    this.title = '';
                    this.message = '';
                }
            }
        },
        methods: {
            confirm() {
                this.resolve(true);
                this.dialog = false;
            },
            open(args) {
                this.title = args.title;
                this.message = args.message;
                this.dialog = true;
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            close() {
                this.resolve(false);
                this.dialog = false;
            }
        }
    }
</script>

<style scoped>

</style>